import classes from './delete-account-form.module.css';



export const DeleteAccountForm = () => {
    return (
        <div className={classes.root}>
          <div className={classes.content}>
            <form
              action="mailto:support@archrival.com?subject=Account deleting"
              method="post"
              encType="text/plain"
            >
              <h2 className={classes.heading}>Dear Archrival Support Team,</h2>
              <h5 className={classes.description}>
                I hope this message finds you well. I am writing to formally request the deletion of my
                user account and all associated personal data from your system. My account details are
                as follows:
              </h5>
              <label for="email">Email/Username
                <input type="email" name="email" id="email" required className={classes.input} />
                    </label>
                    <br/>
              <label for="team">Team <br/>
                <input type="text" name="team" id="team" required className={classes.input} />
              </label>
              <h5 className={classes.description}>
                Thank you for your prompt attention to this matter. I look forward to a smooth and
                secure account deletion process.
              </h5>
              <h5 className={classes.description}>Best regards,</h5>
              <button type="submit" className={classes.button}>Delete account and personal data</button>
            </form>
    
            <h5 className={classes.description}>User related information/data to be deleted:</h5>
            <ol>
              <li>Avatar</li>
              <li>Full Name</li>
              <li>Email</li>
              <li>Phone</li>
              <li>Group belonging inside the system</li>
            </ol>
          </div>
        </div>
      );
}