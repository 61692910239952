import './App.css';
import { DeleteAccountForm } from './components/delete-account-form/delete-account-form';

function App() {
  return (
    <div className="App">
     <DeleteAccountForm />
    </div>
  );
}

export default App;
